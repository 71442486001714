import React from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const Heading = styled(animated.h1)`
  font-size: 6rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 0;
`;

const Subheading = styled(animated.h2)`
  font-size: 2rem;
  color: #777;
  margin-top: 2rem;
  text-align: center;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  height: 5rem;
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
`;

const WorkInProgressSite = () => {
  const containerProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
  });

  const headingProps = useSpring({
    from: { opacity: 0, transform: "translateY(-100px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: 1000,
  });

  const subheadingProps = useSpring({
    from: { opacity: 0, transform: "translateY(100px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    delay: 1500,
  });

  return (
    <Container style={containerProps}>
      <Heading style={headingProps}>blockted</Heading>
      <Subheading style={subheadingProps}>Work in Progress</Subheading>
      <Footer>team@blockted.com</Footer>
    </Container>
  );
};

export default WorkInProgressSite;
